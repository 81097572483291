:root {
	color-scheme: dark;
    --scrollbar-bg: #0e141b;
    --scrollbar-thumb: #b8b7b8;
    --selection-background: #2b333b;
    --selection-text: white;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: var(--scrollbar-bg);
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--scrollbar-thumb);
    border: 2px solid var(--scrollbar-bg);
}

::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: transparent;
}

::selection {
    background-color: var(--selection-background);
    color: var(--selection-text,white);
    -webkit-text-fill-color: var(--selection-text) !important;
    background-image: none !important;
    background-clip: revert !important;
    text-shadow: none !important;
}

body {
	background: #120f16;
	color: #fff;
	height: 100vh;
	font-family: "Montserrat", sans-serif;
}

.App {
    max-width: 100vw;
    overflow: hidden;
    position: relative;
    min-height: 100vh;
}

.header-blur {
	background-position: 50% 50%;
	background-size: cover;
	position: absolute;
	left: -10%;
	top: -250px;
	width: 120%;
	height: 450px;
	filter: blur(125px);
	opacity: .7;
}

.container {
	max-width: 960px;
	position: relative;
	margin: 0 auto;
}

.section-title {
	display: block;
	font-size: 68px;
	font-weight: 700;
	margin: 0 0 15px;
	z-index: 2;
	text-shadow: 0 0 24px rgba(255,255,255,0.1);
	line-height: 1;
}

.section-sub-title {
	display: block;
	font-size: 20px;
	font-weight: 400;
	margin: 0 0 40px;
	z-index: 2;
	text-shadow: 0 0 24px rgba(4,236,166,0.25);
	line-height: 1.45;
	color: #07b36f;
}

#events {
	padding-top: 100px;

	.events-wrapper {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 30px;
		margin-bottom: 30px;
	}
}

#sponsors {
	position: relative;
	background: rgba(4, 236, 166, .125);
	border-top: 2px solid rgb(89, 246, 199);
	border-bottom: 2px solid rgb(89, 246, 199);
    width: 100%;
    height: 135px;
    margin: 30px 0;

	&::after,
	&::before {
		content: '';
		position: absolute;
		top: -2px;
		left: 0;
		right: 0;
		height: 2px;
		background: rgba(4, 236, 166, 1);
		filter: blur(12px);
	}

	&::after {
		top: auto;
		bottom: -2px;
	}

	.container {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		img {
			max-height: 65px;
			max-width: 125px;
			filter: drop-shadow(0 0 15px rgba(255, 255, 255, .35));
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-o-user-select: none;
			user-select: none;
			-webkit-user-drag: none;
			-khtml-user-drag: none;
			-moz-user-drag: none;
			-o-user-drag: none;
			user-drag: none;
		}
	}
}

#aboutus {
	position: relative;
	margin: 120px 0;

	.container {
		p {
			display: block;
			font-size: 22px;
			font-weight: 300;
			margin: 0 0 40px;
			z-index: 2;
			text-shadow: 0 0 12px rgba(0,0,0,0.15);
			line-height: 1.75;
		}

		.since {
			margin: 0;
			width: 155px;
			opacity: 1;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-o-user-select: none;
			user-select: none;
			-webkit-user-drag: none;
			-khtml-user-drag: none;
			-moz-user-drag: none;
			-o-user-drag: none;
			user-drag: none;
		}
	}

	&::before {
		background: url("./assets/img/blob.png") no-repeat;
		background-size: contain;
		content: "";
		filter: blur(25px);
		height: 950px;
		pointer-events: none;
		position: absolute;
		width: 950px;
		z-index: 1;
		top: -340px;
		left: -460px;
		opacity: .4;
	}
}

#team {
	position: relative;
	margin: 120px 0;

	.container {
		.team-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 30px;
		}
	}

	&::before {
		background: url("./assets/img/blob.png") no-repeat;
		background-size: contain;
		content: "";
		filter: blur(25px);
		height: 850px;
		pointer-events: none;
		position: absolute;
		width: 850px;
		z-index: 1;
		top: -420px;
		right: -460px;
		opacity: .4;
	}
}

#gallery {
	position: relative;
	margin: 0;
	height: 350px;

	.swiper {
		height: 100%;
		box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);

		.swiper-slide {
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

#contact {
	position: relative;
	margin: 120px 0;

	.container {
		.contact-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 30px;
		}
	}

	&::before {
		background: url("./assets/img/blob.png") no-repeat;
		background-size: contain;
		content: "";
		filter: blur(25px);
		height: 950px;
		pointer-events: none;
		position: absolute;
		width: 950px;
		z-index: 1;
		top: -340px;
		left: -460px;
		opacity: .4;
	}
}

footer {
	background: #0d0b10;
	padding: 10px 0;

	.container {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		font-size: 14px;

		a {
			color: #fff;
			font-weight: 700;
			text-decoration: none;

			&:hover {
				opacity: .7;
			}
		}
	}
}

.alert {
	padding: 25px;
	text-align: left;
	background: rgba(211, 236, 4, .125);
	border: 3px solid rgb(233, 197, 37);
	box-shadow: 0 0 16px rgba(223, 246, 89, 0.15);
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-flow: column;
	text-decoration: none;
	color: #fff;
	text-shadow: 0 0 6px rgba(255,255,255,.2);
	border-radius: 15px;

	.alert-head {
		display: block;
		font-size: 20px;
		font-weight: 700;
		margin: 0 0 10px;
		z-index: 2;
		line-height: 1.45;
		color: #fff;
		text-transform: uppercase;
	}

	.alert-body {
		display: block;
		font-size: 18px;
		font-weight: 300;
		margin: 0;
		z-index: 2;
		line-height: 1.9;
		text-align: left;

		a {
			color: #fff;
			font-weight: 600;

			&:hover {
				opacity: .7;
			}
		}
	}

	&-danger {
		background: rgba(236, 4, 4, 0.125);
		border: 3px solid rgb(233, 37, 37);
		box-shadow: 0 0 16px rgba(246, 89, 89, 0.15);
	}
}
