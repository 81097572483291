.nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 70px;
    z-index: 10;
    background: rgba(0, 0, 0, .25);
    backdrop-filter: blur(25px);
    border-bottom: 1px solid rgba(200, 200, 200, .065);
    z-index: 999;

    .container {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .nav-menu {
            display: flex;
            flex-flow: row;
        }
    }
}

.nav-brand {
    cursor: pointer;
    position: fixed;
    width: 110px;
    height: 110px;
    left: calc(50% - 480px);
    top: 0;
    padding: 8px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    z-index: 1000;
    background: rgba(0, 0, 0, .35);
    backdrop-filter: blur(15px);
    border: 1px solid rgba(200, 200, 200, .065);
    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.4));

    .logo {
        position: absolute;
        width: 85%;
        height: 85%;
        left: 7.5%;
        top: 7.5%;
        transition: all .2s ease;
    }

    .vinyl {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        -webkit-animation: spin 3s linear infinite;
        -moz-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
        opacity: 0;
        transition: all .2s ease;
    }

    .stylus {
        position: absolute;
        width: 70%;
        height: 70%;
        left: 35px;
        top: 5px;
        opacity: 0;
        transform: rotate(-25deg);
        transform-origin: top right;
        transition: all .2s ease;
    }

    &:hover {
        .logo {
            width: 30%;
            height: 30%;
            left: 35%;
            top: 35%;
            -webkit-animation: spin 3s linear infinite;
            -moz-animation: spin 3s linear infinite;
            animation: spin 3s linear infinite;
            transition: all .2s ease;
        }

        .vinyl {
            opacity: 1;
            transition: all .2s ease;
        }

        .stylus {
            transform: rotate(-16deg);
            opacity: 1;
            transition: all .2s ease;
        }
    }
}

.nav-link {
    cursor: pointer;
    height: 100%;
    width: 150px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
    opacity: .7;
    transition: all .25s ease;
    // border-left: 1px solid rgba(200, 200, 200, .065);

    &:last-of-type {
        // border-right: 1px solid rgba(200, 200, 200, .065);
    }

    i {
        z-index: 5;
        font-size: 24px;
        margin-right: 10px;
    }

    span {
        font-size: 14px;
        font-weight: 700;
    }

    &::before {
        position: absolute;
        content: '';
        width: 40px;
        height: 10px;
        background: #07b36f;
        border-radius: 50%;
        filter: blur(15px);
        opacity: 0;
        transition: all .25s ease;
    }

    &.active,
    &:hover {
        color: #07b36f;
        opacity: 1;
        transition: all .25s ease;

        &::before {
            opacity: 1;
            transition: all .25s ease;
        }
    }
}

@-moz-keyframes spin { 
    100% {
        -moz-transform: rotate(360deg);
    } 
}

@-webkit-keyframes spin { 
    100% {
        -webkit-transform: rotate(360deg);
    } 
}

@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
