@import "atropos/scss";

.team-box {
	position: relative;
	display: block;
	margin-bottom: 30px;

	.atropos {
		max-width: 960px;
		width: 100%;
		height: 300px;
		position: relative;

		.atropos-inner {
			border-radius: 30px;
			box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.overlay {
		background: linear-gradient(8deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		transition: all .2s ease;
	}

	.information {
		position: absolute;
		bottom: 20px;
		left: 20px;
		right: 20px;
		display: flex;
		flex-flow: column;
		text-shadow: 0 0 12px rgba(0,0,0,0.4);
		text-align: center;
		transition: all .2s ease;

		.title {
			color: #fff;
			font-size: 26px;
			z-index: 5;
			font-weight: 700;
		}

		.date {
			color: #fff;
			opacity: 1;
			font-size: 14px;
			z-index: 5;
			font-weight: 600;
			margin-top: 8px;
		}
	}

	&:hover {
		background-size: 105%;
		transition: all .2s ease;

		.overlay {
			opacity: .8;
			transition: all .2s ease;
		}

		.information {
			opacity: 1;
			text-shadow: 0 0 12px rgba(0,0,0,0.25);
			transition: all .2s ease;
		}
	}
}
