@import "atropos/scss";

.contact-box {
	position: relative;
	display: block;
	text-decoration: none;
	color: #fff;

	.atropos {
		width: 100%;
		height: 250px;
		position: relative;
		padding: 0;

		.atropos-inner {
			text-align: center;
			background: rgba(4, 236, 166, .125);
			border: 2px solid rgb(89, 246, 199);
			box-shadow: 0 0 16px rgba(89, 246, 199, .15);
			text-shadow: 0 0 6px rgba(255,255,255,.2);
			border-radius: 30px;

			div {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-flow: column;
				height: 100%;

				i {
					font-size: 80px;
					margin-bottom: 35px;
				}
			
				span {
					font-weight: 600;
					font-size: 20px;
					text-transform: uppercase;
				}
			}
		}
	}
}
