@media only screen and (max-width: 768px) {
	.nav {
		top: auto;
		bottom: 0;
		height: 75px;
		border-bottom: 0;
		border-top: 1px solid rgba(200, 200, 200, 0.065);
		background: rgba(0, 0, 0, 0.35);

		.container {
			padding: 0;

			.nav-menu {
				width: 100%;
				height: 100%;
				display: grid;
				grid-template-columns: repeat(4, 1fr);
			}
		}

		.nav-link {
			width: auto;
			flex: 1 1 auto;
			display: flex;
			flex-flow: column;

			i {
				font-size: 30px;
				margin: 0 0 5px;
			}

			span {
				font-size: 11px;
			}
		}
	}

	.nav-brand {
		width: 80px;
    	height: 80px;
		left: auto;
	}

	.container {
		padding: 0 15px;
	}

	.section-title {
		font-size: 52px;
	}

	.section-sub-title {
		font-size: 18px;
	}
	
	#events {
		padding-top: 30px;

		.events-wrapper {
			grid-template-columns: repeat(1, 1fr);

			.event-box {
				&:first-of-type {
					grid-column: auto;
	
					.atropos {
						height: 250px;
					}
				}

				.information {
					opacity: 1;
					bottom: 25px;
					left: 25px;
					right: 25px;

					.title {
						font-size: 22px;
					}

					.date {
						font-size: 14px;
						font-weight: 700;
						margin-top: 5px;
					}
				}

				.overlay {
					opacity: 1;
				}
			}
		}
	}

	#aboutus {
		margin: 60px 0;

		&::before {
			height: 450px;
			width: 450px;
			top: -247px;
			left: -230px;
			opacity: 0.4;
		}
	}

	#sponsors {
		height: auto;
		padding: 15px 0;

		.container {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: 1fr;
			grid-gap: 10px;
			align-items: center;
			justify-items: center;
			
			img {
				max-width: 100px;
				min-height: 75px;
				object-fit: contain;
			}
		}
	}

	#team {
		margin: 60px 0;

		.container {
			.team-grid {
				grid-template-columns: repeat(1, 1fr);
				margin-bottom: 30px;

				.team-box {
					margin-bottom: 0;
				}
			}
		}
	}

	#contact {
		margin: 60px 0;
		.container {
			.contact-grid {
				grid-template-columns: repeat(1, 1fr);
			}
		}
	}

	footer {
		margin-bottom: 75px;

		.container {
			flex-flow: column;
			align-items: center;
		}
	}
}
